import '../styles/global.scss'; /* webpackPreload: true */

import Logo from '@components/bits/icons/Logo';
import Cookies from '@components/layout/Cookies';
import Footer from '@components/layout/Footer';
import Header from '@components/layout/Header';
import Plug from '@components/plugs/Plug';
import * as gtag from '@utils/gtag';
import Head from 'next/head';
import router from 'next/router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}): JSX.Element => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const myErrorHandler = (error: Error, info: { componentStack: string }): void => {
  // Do something with the error
  // E.g. log to an error logging client here
  console.log('error:', error);
  console.log('info:', info);
};

type Props = {
  Component;
  pageProps;
};

const App: FunctionComponent<Props> = ({ Component, pageProps }): JSX.Element => {
  const [cookie, setCookie] = useState(null);

  const {
    global: {
      content: { mainNavigation, footer, logo, cookies },
    },
  } = pageProps;

  useEffect(() => {
    if (cookie) {
      window.localStorage.setItem('cookie', JSON.stringify(cookie));
    }
  }, [cookie]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (typeof window !== 'undefined' && url) {
        gtag.pageview(url);
        // fbq.pageview();
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // cookies
    const lsCoockie = window.localStorage.getItem('cookie');
    if (lsCoockie) {
      setCookie(JSON.parse(lsCoockie));
    } else {
      const newD = {};

      Object.keys(cookies.settings).forEach((val) => {
        const elm: { title: string; preselcted: boolean } = cookies.settings[val];
        newD[elm.title] = elm.preselcted;
      });

      updateCookies(newD);
    }
  }, []);

  const updateCookies = (newCookie): void => {
    const nc = { ...cookie, ...newCookie };
    setCookie(nc);
  };

  useEffect(() => {
    if (cookie) {
      window.localStorage.setItem('cookie', JSON.stringify(cookie));
    }
  }, [cookie]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      {/* TODO: add this, <DynamicHead ogImage={ogImage} ogDescription={description}>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </DynamicHead> */}

      <div className="ie">
        {logo && <Logo url={logo?.asset?.url} />}
        <p>Din nettleser er utdatert, du kan åpne nettsiden vår i Microsoft Edge.</p>
      </div>

      {cookie?.seenCookie ? null : (
        <Cookies cookieData={cookie} updateCookies={updateCookies} {...cookies} />
      )}

      {/* <Debug /> */}

      <Header key={'header'} mainmenu={mainNavigation} logo={logo} />
      <span>
        <Component key={'component'} {...pageProps} />
      </span>

      <Plug key={'plug'} {...footer.plug}>
        <Footer key={'footer'} {...footer} />
      </Plug>
    </ErrorBoundary>
  );
};

export default App;
