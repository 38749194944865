import iub from '@utils/ImageUrlBuilder';
import { PortableText } from '@utils/sanity/sanity';
import React, { FunctionComponent, memo } from 'react';
import ReactPlayer from 'react-player';

import styles from './index.module.scss';

const serializers = {
  // eslint-disable-next-line react/display-name
  types: {
    // eslint-disable-next-line react/display-name
    figure: (props) => {
      return <img alt="" src={iub(props.node).width(1000).url()} />;
    },
    // eslint-disable-next-line react/display-name
    videoUrl: (props) => {
      return (
        <div className={styles.video}>
          <ReactPlayer controls={true} width={'100%'} height={'100%'} url={props.node.videoURL} />
          {props.node.caption && <span> {props.node.caption} </span>}
        </div>
      );
    },
  },

  marks: {
    // eslint-disable-next-line react/display-name
    internalLink: ({ mark, children }) => {
      const { slug = {} } = mark;
      const href = `/${slug.current}`;

      return <a href={href}>{children}</a>;
    },
    // eslint-disable-next-line react/display-name
    garamond: ({ children }) => {
      return <span className="italic_garamond">{children}</span>;
    },
    textOverVideo: (props): JSX.Element => {
      return <span className="text-over-video">{props.children}</span>;
    },
  },
};

type Props = {
  data;
};

const SimpleBlockContent: FunctionComponent<Props> = ({ data }) => {
  if (!data) return null;
  return <PortableText className={styles.root} blocks={data} serializers={serializers} />;
};

export default memo(SimpleBlockContent);
