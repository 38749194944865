const sanityClient = require('@sanity/client');
require('dotenv').config();

export default sanityClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET, //'production',
  apiVersion: '2021-05-25', // TODO: figure out how to set this up,
  useCdn: process.env.SANITY_USE_CDN || false,
  // withCredentials: true,
  // token: process.env.SANITY_USE_CDN ? false : process.env.SANITY_API_TOKEN,
});
