import React, { FunctionComponent, useEffect, useRef } from 'react';

import styles from './index.module.scss';

// const fps = 60;
// const fpsInterval = 1000 / fps;
// let then = null;

type Props = {
  className?: string;
  children:
    | {
        props;
      }
    | any;
};

type StyleProps = { backgroundColor?; backgroundImage?; backgroundSize? };

const Plug: FunctionComponent<Props> = ({ children }) => {
  const props = children?.props;
  const bgColorRef = useRef(
    props?.plug?.backgroundColor?.backgroundColor || props?.backgroundColor?.backgroundColor
  );
  // const bgImage = props?.plug?.backgroundImage?.backgroundImage?.asset?.url;
  const elementRef = useRef(null);
  const animationRef = useRef<number | null>(null);
  const scrollRef = useRef({
    scrollPos: 0,
    scrollTo: 0,
    speed: 15,
    fps: 60,
    fpsInterval: 1000 / 60,
    then: null,
  });
  const imageRef = useRef(props?.plug?.backgroundImage?.backgroundImage?.asset?.url);

  const style: StyleProps = {};

  if (bgColorRef.current) {
    style.backgroundColor = bgColorRef.current;
  }

  if (imageRef.current) {
    style.backgroundImage = `url(${imageRef.current}`;
    // style.backgroundSize = 'cover';
    // style.transform = ';
  }

  const anim = (now: number): void => {
    if (!scrollRef.current.then) {
      scrollRef.current.then = now;
    }

    const elapsed = now - scrollRef.current.then;

    const sp = document.documentElement.scrollTop;

    if (elapsed > scrollRef.current.fpsInterval && scrollRef.current.scrollPos != sp) {
      scrollRef.current.scrollTo = sp;

      const th = document.documentElement.clientHeight;

      scrollRef.current.scrollPos +=
        (scrollRef.current.scrollTo - scrollRef.current.scrollPos) / scrollRef.current.speed;

      const p = (scrollRef.current.scrollPos / th) * 1.5;

      scrollRef.current.then = now - (elapsed % scrollRef.current.fpsInterval);

      if (
        elementRef.current &&
        Math.abs(scrollRef.current.scrollPos - scrollRef.current.scrollTo) > 0.0015
      ) {
        elementRef.current.style.backgroundPosition = `center ${Math.max(-1, -10 + 10 * p)}%`;
      }
    }

    animationRef.current = requestAnimationFrame(anim);
  };

  useEffect(() => {
    if (imageRef.current) {
      animationRef.current = requestAnimationFrame(anim);
    }

    return () => cancelAnimationFrame(animationRef.current);
  }, []);

  return (
    <div ref={elementRef} style={style} className={`plug ${styles.root} ${props._type}`}>
      <div className={'SC__content-container-gutter-wrapper'}>
        <div className={`SC__content-container`}>{children}</div>
      </div>
    </div>
  );
};

export default Plug;
