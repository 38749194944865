import RouteList from '@bits/RouteList';
import HamburgerMenu from '@components/bits/HamburgerMenu';
import CloseXButton from '@components/bits/icons/CloseXButton';
import Logo from '@components/bits/icons/Logo';
import LocalesTriggers from '@components/bits/LocalesTriggers';
import useWindowDimensions from '@utils/hooks/useWindowDimension';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useState } from 'react';

import styles from './index.module.scss';

type Props = {
  mainmenu;
  logo;
};
interface Size {
  width: number | undefined;
  height: number | undefined;
}

const Header: FunctionComponent<Props> = ({ logo, mainmenu }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const router = useRouter();

  const size: Size = useWindowDimensions();
  const color = 'black';
  const colorClass = '';

  const toggleMenu = (): void => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'auto';
    }

    return () => {
      document.documentElement.style.overflowY = 'auto';
    };
  }, [menuOpen]);

  useEffect(() => {
    setMenuOpen(false);
  }, [router]);

  return (
    <div className={`page-header ${colorClass} ${styles.root}`}>
      {size.width <= 768 ? (
        <>
          <div className={styles['_menu-items']}>
            <Link href={'/'}>
              <span className={styles['_logo']}>
                <Logo url={`${logo?.asset?.url}`} />
              </span>
            </Link>
            <LocalesTriggers />
            <HamburgerMenu color={color} toggleMenu={toggleMenu} />
          </div>
          <span className={`${styles['_mobile-menu-panel']} ${menuOpen ? styles['_open'] : ''}`}>
            <div className={styles['_top']}>
              <span>Close</span>
              <CloseXButton
                classes={styles['_close']}
                color="black"
                onClick={() => setMenuOpen(false)}
              />
            </div>
            <div className={styles['_content']}>
              {mainmenu.length &&
                mainmenu?.map((val, key) => {
                  const { _type } = val;

                  if (_type == 'route') {
                    // const url = val?.director?.slug?.current;
                    const url = val.director.slug.current !== '/' ? val.director.slug.current : '';
                    return (
                      <Link key={key} href={`${url}`}>
                        {val.director.title}
                      </Link>
                    );
                  }

                  if (_type == 'routeList') {
                    return <RouteList key={key} {...val} />;
                  }

                  return null;
                })}
            </div>
            <LocalesTriggers />
          </span>
        </>
      ) : (
        // DESKTOP
        <>
          <Link href={'/'}>
            <span className={styles['_logo']}>
              <Logo url={`${logo?.asset?.url}`} />{' '}
            </span>
          </Link>
          <div className={styles['_menu-items']}>
            {mainmenu.length &&
              mainmenu?.map((val, key) => {
                const { _type } = val;

                if (_type == 'route') {
                  const url = val.director.slug.current !== '/' ? val.director.slug.current : '';

                  return (
                    <span key={key} className={`${val.director.extraVisible ? 'bold' : ''}`}>
                      <Link href={`/${url}`}>{val.director.title}</Link>
                    </span>
                  );
                }

                if (_type == 'routeList') {
                  return <RouteList key={key} {...val} />;
                }

                return null;
              })}
          </div>
          <LocalesTriggers />
        </>
      )}
    </div>
  );
};

export default Header;
